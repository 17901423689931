import styled from 'styled-components';

export const InfoSec = styled.div`
  padding: 160px 0;
  background: ${({ lightBg }) => (lightBg ? '#ffffff' : '#eaeaea')};
  color: #242424;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px -15px -15px;
  flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
  flex: 1;
  flex-basis: 50%;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 50%;
  
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const TopLine = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.4px;
  color: ${({lightTopLine}) => (lightTopLine ? '#7f8996' : '#49B7A5')};
`;

export const Heading = styled.div`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  color: ${({lightText}) => (lightText ? '#242424' : '#1c2237')};
`;

export const Subtitle = styled.p`
    margin-bottom: 35px;
    max-width: 440px;
    font-size: 18px;
    line-height: 24px;
    color: ${({lightTextDesc}) => (lightTextDesc ? '#7f8996' : '#1c2237')};
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
  max-width: 555px;
`;

export const Img = styled.img`
  display: inline-block;
  max-width: 100%;
  max-height: 500px;
  padding-right: 0;
  vertical-align: middle;
  border: 0;
`;


import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    Img,
    MobileIcon,
} from './Navbar.elements';

const Navbar = () => {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <IconContext.Provider value={{ color: '#ffffff' }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to="/">
                            <Img src={require('../../images/logo.svg').default} alt="Logo" />
                            Айконтим
                        </NavLogo>
                        <MobileIcon
                            onClick={ handleClick }
                        >
                            { click ? <FaTimes /> : <FaBars /> }
                        </MobileIcon>
                    </NavbarContainer>
                </Nav>
            </ IconContext.Provider>
        </>
    );
};

export default Navbar;

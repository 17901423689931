import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sana Pro', sans-serif;
  }
`;

export const Container = styled.div`
  margin: 0  auto;
  padding:  0 50px;
  width: 100%;
  max-width: 1300px;
  z-index: 1;
  
  @media screen and (max-width: 991px) {
    padding: 0 30px;
  }
`;

export const Button = styled.button`
  padding: ${({big}) => (big ? '12px 64px' : '10px 20px')};
  white-space: nowrap;
  border-radius: 4px;
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  background: ${({primary}) => (primary ? '#4B59F7' : '#0467FB')};
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  
  &:hover {
    transition: all 0.3s ease-out;
    background: #ffffff;
    background: ${({primary}) => (primary ? '#4B59F7' : '#0467FB')};
  }
  
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  
`;

export default GlobalStyle;

import React, { useState }  from 'react';
import { 
    FooterContainer,
    FooterSubscription,
    FooterSubHeading,
    FooterSubText,
    Form,
    FormInput,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights
} from './Footer.elements'; 
import { Button } from '../../globalStyles';



const Footer = () => {

    const [email, setEmail] = useState(
        //'test+'+Date.now().toString()+'@mail.com'
        ''
        ); // '' is the initial state value

    function handleSubmit(e){
        e.preventDefault();
        
        fetch("/subscribe.php?email="+email)
            .then(res => res.json())
            .then(
                (result) => {
                    alert('Вы успешно подписались!');
                    setEmail('');
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    alert('Ошибка! Попробуйте, пожалуйста, позднее.');
                }
            );
    }

    return (
        <FooterContainer>
            <FooterSubscription>
                <FooterSubHeading>
                    Присоединяйтесь к нашей команде, чтобы получать последние новости
                </FooterSubHeading>
                <FooterSubText>
                    Вы можете отказаться от подписки в любое время.
                </FooterSubText>
                <Form onSubmit={handleSubmit}>
                    <FormInput name="email" type="email" placeholder="Ваш Email" value={email} onInput={e => setEmail(e.target.value)}/>
                    <Button fontBig>Подписаться</Button>
                </Form>
            </FooterSubscription>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/">
                        Айконтим
                    </SocialLogo>
                    <WebsiteRights>IconSoft © 2022</WebsiteRights>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterContainer>
    );
};

export default Footer;
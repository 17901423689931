export const homeObjOne = {
    lightBg: true,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightTextDesc: true,
    buttonLabel: 'Попробовать бесплатно',
    description: 'Решение Айконтим разработано таким образом, чтобы каждый член вашей команды мог эффективно планировать выполнение своих задач.',
    headLine: 'Лучший инструмент управления проектами',
    lightText: true,
    topLine: 'Отечественная цифровизация IconSoft',
    img: require('../../images/undraw_mobile_wireframe_re_jxui.svg').default,
    alt: 'Image',
    start: ''
};

export const homeObjTwo = {
    lightBg: false,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightTextDesc: false,
    buttonLabel: 'Попробовать бесплатно',
    description: 'Создавайте проекты, планируйте выполнение задач, распределяйте задачи в своей команде.',
    headLine: 'Планируйте',
    lightText: false,
    topLine: 'Отечественная цифровизация IconSoft',
    img: require('../../images/undraw_business_plan_re_0v81.svg').default,
    alt: 'Image',
    start: 'true'
};

export const homeObjThree = {
    lightBg: true,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightTextDesc: true,
    buttonLabel: 'Попробовать бесплатно',
    description: 'Расставляйте приоритеты и обсуждайте работу своей команды в условиях абсолютной прозрачности.',
    headLine: 'Отслеживайте',
    lightText: true,
    topLine: 'Отечественная цифровизация IconSoft',
    img: require('../../images/undraw_personal_goals_re_iow7.svg').default,
    alt: 'Image',
    start: ''
};

export const homeObjFour = {
    lightBg: false,
    primary: true,
    imgStart: 'start',
    lightTopLine: false,
    lightTextDesc: false,
    buttonLabel: 'Попробовать бесплатно',
    description: 'Повышайте производительность команды, опираясь на визуальные данные, доступные ее членам в реальном времени.',
    headLine: 'Создавайте отчеты',
    lightText: false,
    topLine: 'Отечественная цифровизация IconSoft',
    img: require('../../images/svg-3.svg').default,
    alt: 'Image',
    start: 'true'
};

export const homeObjFive = {
    lightBg: true,
    primary: false,
    imgStart: '',
    lightTopLine: false,
    lightTextDesc: false,
    buttonLabel: 'Попробовать бесплатно',
    description: 'Повышайте производительность команды, опираясь на визуальные данные, доступные ее членам в реальном времени.',
    headLine: 'Kanban-доски',
    lightText: false,
    topLine: 'Отечественная цифровизация IconSoft',
    img: require('../../images/undraw_scrum_board_re_wk7v.svg').default,
    alt: 'Image',
    start: 'true'
};